import React, { useContext } from "react"
import { FilterContext, type IFilterContext } from "../filter/FilterContext"
import {
  type AvailableFilter,
  type AvailableFilterOption,
  availableFilters,
} from "./availableFilters"
import { Chip } from "./Chip"

import "./GranteeFilterMenu.scss"

export const GranteeFilterChips: React.FC = () => {
  const { clearFilters, filters, filterCount, setFilters, toggleOption } =
    useContext(FilterContext) as IFilterContext

  const removeFilter = (
    available: AvailableFilter,
    option: AvailableFilterOption
  ) => {
    setFilters(toggleOption(available, option, true))
  }

  if (filterCount() === 0) {
    return null
  }

  return (
    <div className="flex flex-wrap gap-[8px] mt-[40px] items-center">
      {availableFilters.map((available: AvailableFilter<any>) =>
        available.options.map(
          (option) =>
            filters[available.key]?.some((selected: any) =>
              available.equals(selected, option.value)
            ) && (
              <Chip
                key={`${available.key}-${option.name}`}
                color={available.getColor(option)}
                onRemove={() => removeFilter(available, option)}
              >
                {available.format(option)}
              </Chip>
            )
        )
      )}
      <button className="btn btn-link" onClick={clearFilters}>
        Clear All
      </button>
    </div>
  )
}

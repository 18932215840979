import type { ChangeEvent, KeyboardEvent } from "react"
import React, { useContext, useRef, useEffect } from "react"
import { Page } from "../components/layout"
import {
  filterGrantees,
  type Grantee,
  GranteeCard,
} from "../components/grantees"
import type { Sort } from "../components/filter/FilterContext"
import {
  FilterAllAtOnceProvider,
  FilterContext,
  FilterProvider,
  type IFilterContext,
} from "../components/filter/FilterContext"
import allGrantees from "../data/granteeData"
import { GranteeFilterMenu } from "../components/grantees/GranteeFilterMenu"
import { GranteeFilterChips } from "../components/grantees/GranteeFilterChips"
import FilterIcon from "../images/filter_icon.inline.svg"
import SearchIcon from "../images/search_icon.inline.svg"
import "./grantees.scss"

export const GranteeList = () => {
  const { filters, query, setQuery, setSort, sort } = useContext(
    FilterContext
  ) as IFilterContext
  const inputEl = useRef<HTMLInputElement>(null)
  const mobileMenu = useRef<HTMLDivElement>(null)

  function search() {
    setQuery(inputEl.current?.value)
  }

  function resetSearch() {
    if (!inputEl.current?.value.length) {
      setQuery(undefined)
    }
  }

  function toggleMenu() {
    document.body.classList.toggle(`fixed`)
    mobileMenu.current?.classList.toggle(`show`)
  }

  function searchOnEnterKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === `Enter`) {
      search()
    }
  }

  function sortBy(event: ChangeEvent<HTMLSelectElement>) {
    setSort((event.currentTarget?.value as Sort) || undefined)
  }

  const grantees = filterGrantees(
    allGrantees as Grantee[],
    query ?? ``,
    sort ?? undefined,
    filters
  )

  useEffect(() => {
    document.body.addEventListener(`search`, resetSearch)
    return () => document.body.removeEventListener(`search`, resetSearch)
  }, [])

  return (
    <>
      <div className="lg:flex-grid lg:flex-row flex flex-col gap-[16px]">
        <div className="basis-1/1 lg:basis-2/3 flex gap-[16px]">
          <div className="flex grow input-group">
            <input
              type="search"
              className="w-full form-input"
              ref={inputEl}
              placeholder="Search Within"
              onKeyPress={searchOnEnterKeyPress}
            />
            <button type="button" className="form-button" onClick={search}>
              <SearchIcon />
            </button>
          </div>
          <button
            className="lg:hidden form-button btn-filter-menu"
            onClick={toggleMenu}
          >
            <FilterIcon />
          </button>
        </div>
        <div className="basis-1/1 lg:basis-1/3">
          <select className="w-full form-select" onChange={sortBy}>
            <option value="">Sort By</option>
            <option value="-amount">Highest Award Amount</option>
            <option value="amount">Lowest Award Amount</option>
            <option value="name">Project Title</option>
            <option value="-wave">Date Added</option>
          </select>
        </div>
      </div>
      <GranteeFilterChips />
      <div className="grantee-results-meta">
        Showing {grantees.length} Results
      </div>
      <div className="grantee-list">
        {grantees.map((grantee: Grantee) => (
          <GranteeCard
            grantee={grantee}
            key={grantee.name + ` ` + grantee.wave}
            useFirstChipColor={true}
          />
        ))}
      </div>
      <div className="mobile-menu" ref={mobileMenu}>
        <FilterAllAtOnceProvider>
          <GranteeFilterMenu onApply={toggleMenu} onCancel={toggleMenu} />
        </FilterAllAtOnceProvider>
      </div>
    </>
  )
}

export const GranteesPage = () => (
  <Page
    header="Grantees"
    title="XRPL Award Recipients"
    keywords="nft projects, xrpl projects, xrpl grants recipient, xrpl grants awardee, xrpl grantee, sidechain projects"
    description="XRPL Grants are awarded for projects that add value to the XRPL ecosystem and involve a technical component. See a list of recent XRPL Grantees."
    slug="grantee"
  >
    <FilterProvider>
      <section className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <div className="hidden lg:block lg:col-span-3">
          <GranteeFilterMenu />
        </div>
        <div className="col-span-2 sm:col-span-4 md:col-span-8 lg:col-span-9">
          <GranteeList />
        </div>
      </section>
    </FilterProvider>
  </Page>
)

export default GranteesPage
